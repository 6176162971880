//=================================================
// Block
//=================================================

:not(#page-title):not(#page-content) {
    &.block {
        .title {
            h1, h2, h3, h4, h5, h6 {
                padding-bottom: $space_xs;
                margin-bottom: 0;
            }
        }
    }
}

.block {
    .description {
        max-width: 32.789rem;

        &-lg {
            max-width: 39.842rem;
        }

        &-xl {
            max-width: 54rem;
        }
    }
}

[class*='section-height-full'] {
    > [class*='container'] {
        height: 100%;
    }
}
