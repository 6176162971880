//=================================================
// List group
//=================================================

// List group item unstyled
.list-group {
	margin-left: 0;

	.list-group-item {
		margin-bottom: 0 !important;

		&:before, &:after {
			display: none;
		}
	}
}

.list-group-item-action {
	&.active {
		h1, h2, h3, h4, h5, h6 {
			color: $white !important;
		}
	}
}

[class*='list-wrapper'] {
	margin-left: 0;
}

[class*="icon-list-item"], [class*='nav-menu'] li, [class*='repeater-item'] {
	margin-bottom: 0;
}

[class*="list-item"], [class*='nav-menu'] li, [class*='sitemap-item'], [class*='price-list'] li {
	&:before, &:after {
		display: none;
	}
}
