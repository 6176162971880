//=================================================
// Service
//=================================================

.service {
	@extend .style-default;
	@extend .transition-all;
	position: relative;
	padding: $space_md;
	background-color: $white;
	box-shadow: 0 5px 83px 0 rgba($body_main_element_secondary_color, 0.12);
	height: 100%;

	.service-icon {
		margin-bottom: $space_xs;

		&:last-child {
			margin-bottom: 0;
		}

		> * {
			font-size: 3.684rem;
			color: $body_main_element_primary_color;
		}
	}

	.service-t-head {
		color: $body_headline_color;
	}
}

.service-items {
	margin-top: -3.526rem !important;
}

.with-service-items {
	padding-bottom: 3.526rem;
}

// Page builder
[class*='-element'] {
	&[class*='-service'] {
		height: 100%;

		> [class*='-widget-container'] {
			height: 100%;
		}
	}
}
