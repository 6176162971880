//=================================================
// Process
//=================================================

.process {
	position: relative;
	@extend .style-default;
	@extend .d-flex;
	padding: $space_md;
	height: 100%;
	overflow: hidden;

	.process-content {
		position: relative;
		z-index: 2;
		@extend .align-self-center;

		.process-small-t-head {
			color: $body_main_element_primary_color;
			text-transform: uppercase;
		}

		.process-t-head {
			color: $body_headline_color;
		}
	}

	.process-number {
		position: absolute;
		top: -2.632rem;
		right: -2.105rem;
		font-size: 15.789rem;
		font-family: $font_primary_bold;
		font-weight: 700;
		color: $body_text_secondary_color;
		z-index: 1;
		opacity: 0.03;
	}
}

.process-height {
	.process {
		min-height: 21.053rem;

		@media #{$media_xl} {
			min-height: auto;
		}
	}
}

// Colors
.process-color-1 {
	background-color: #0f4c81;

	.process-content {
		color: #cfd7e7;

		.process-t-head {
			color: $body_text_tertiary_color;
		}
	}

	.process-number {
		color: #fbfbfb;
		opacity: 0.04;
	}
}

.process-color-2 {
	background-color: #f8f8f8;
}

.process-color-3 {
	background-color: #060606;

	.process-content {
		color: #b4b4b4;

		.process-t-head {
			color: $body_text_tertiary_color;
		}
	}

	.process-number {
		color: #fbfbfb;
		opacity: 0.07;
	}
}

// Page builder
[class*='-element'] {
	&[class*='-process'] {
		height: 100%;

		> [class*='-widget-container'] {
			height: 100%;
		}
	}
}
