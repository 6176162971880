//=================================================
// Images
//=================================================

.img {
	&.object-fit {
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 100%;
		}

		.object-fit-cover {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			outline: none;

			img, svg {
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
				-o-object-fit: cover;
			}

			figure {
				width: 100%;
				height: 100%;
			}
		}

		// Background color
		.img-bg-color {
			content: '';
			background-color: $body_main_element_primary_color;
			opacity: 0.25;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		// Sizes
		&.size-1 {
			&:before {
				padding-top: 52%;
			}
		}

		&.size-2 {
			&:before {
				padding-top: 64%;
			}
		}

		&.size-3 {
			&:before {
				padding-top: 76%;
			}
		}

		&.size-4 {
			&:before {
				padding-top: 88%;
			}
		}

		&.size-5 {
			&:before {
				padding-top: 112%;
			}
		}
	}

	// Width content
	.object-content {
		color: $body_text_tertiary_color;
		position: absolute;
		top: 50%;
		right: $space_md;
		@include transform(translateY(-50%));
		max-width: 300px;
		margin-left: $space_md;
		padding-top: $space_md;
		padding-bottom: $space_md;

		.items {
			.item {
				margin-bottom: $space_sm;

				&:last-child {
					margin-bottom: 0;
				}

				h1, h2, h3, h4, h5, h6, p, a {
					color: inherit;
				}
			}
		}
	}
}

/*=============================
// Image size
=============================*/
.img-size-1 {
	.img {
		&.object-fit {
			min-height: 400px;

			&:before {
				padding-top: 50%;
			}
		}
	}
}

.img-size-2 {
	.img {
		&.object-fit {
			&:before {
				padding-top: 33.77%;
			}
		}
	}
}

// Blur filter
.img-blur {
	filter: blur(9px);
	-webkit-filter: blur(9px);
}

// Figure
.figure-img {
	margin-bottom: 0;
}
