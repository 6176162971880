//=================================================
// Signature
//=================================================

.signature {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .style-default;

  .signature-img {
    padding-right: $space_md;

    &:last-child {
      padding-right: 0;
    }

    img {
      max-width: 6.737rem !important;
      width: 100%;
    }
  }

  .signature-content {
    .signature-name {
      color: $body_headline_color;
    }

    .signature-position {
      margin-top: -0.895rem;
    }
  }
}
