//=================================================
// Reviews
//=================================================

.reviews-items {
    .reviews-item {
        width: 100%;
    }
}

/*=============================
// Reviews slider
=============================*/
.adv-slider-reviews {
    position: relative;
    padding-left: 3.684rem;
    padding-top: 2.421rem;

    .adv-slider-reviews-img {
        position: absolute;
        left: 0;
        max-width: 5.895rem;

        img {
            width: 100%;
        }
    }

    .adv-swiper-container {
        &.reviews-text {
            .reviews-text-items {
                .reviews-text-item {
                    .reviews-text-item-content {
                        > * {
                            font-family: $font_primary;
                            font-weight: 400;
                            line-height: 1.67;
                            @include lhCrop-b(1.67, 0.75);

                            &:last-child {
                                @include lhCrop-a(1.67, 0.75);
                            }

                            &:before, &:after {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .thumbs {
        margin-top: $space_lg - $h2_font_mb;
        max-width: 500px;

        @media #{$media_md} {
            flex-direction: column !important;
            margin-left: -3.684rem;
        }

        .adv-swiper-container {
            &.reviews-thumbs {
                width: 11.053rem;
                height: 3.158rem;
                margin: 0;
                margin-right: 0.579rem;

                @media #{$media_md} {
                    margin-right: -1.053rem;
                }

                .reviews-thumbs-items {
                    .reviews-thumbs-item {
                        @extend .d-flex;
                        @extend .align-items-center;
                        @extend .transition-all;
                        width: 2.632rem !important;
                        margin-right: 1.053rem !important;

                        img {
                            width: 2.632rem !important;
                            height: 2.632rem !important;
                            border-radius: 50%;
                            cursor: pointer;
                            @extend .transition-all;
                            max-width: inherit !important;
                        }

                        &.adv-swiper-slide-thumb-active {
                            width: 3.158rem !important;

                            img {
                                width: 3.158rem !important;
                                height: 3.158rem !important;
                            }
                        }
                    }
                }
            }
        }

        .reviews-results {
            @extend .style-default;

            @media #{$media_md} {
                margin-top: 1.053rem;
                text-align: center;
            }

            .reviews-name {
                font-family: $font_primary_bold;
                font-weight: 700;
            }

            .reviews-positions {
                margin-top: -0.895rem;
                font-size: $f_size_df;
                color: #777777 !important;
            }
        }
    }
}
