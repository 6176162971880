//=================================================
// Nav
//=================================================

.nav {
	font-size: $f_size_df;
	@extend .list-unstyled;

	li {
		margin-bottom: 0;
	}

	// Nav item
	.nav-item {
		font-family: $font_primary_bold;
		font-weight: 700;

		> a:not(.disabled):not(.active) {
			color: $body_links_color;
		}

		// Hover
		&:hover {
			> a:not(.disabled):not(.active) {
				color: $body_links_hover_color;
			}
		}

		// Has children
		&.nav-item-has-children {
			> a:not(.disabled) {
				font-family: $font_primary_bold;
				font-weight: 700;
			}
		}

		// Active
		&.current-nav-item, &.active {
			> a:not(.disabled) {
				font-family: $font_primary_bold;
				font-weight: 700;
				color: $body_links_hover_color;
			}

			> .dropdown-icon {
				[class*='fa-'] {
					color: $body_links_hover_color;
				}
			}
		}
	}

	// Nav link
	&:not(.nav-pills) {
		.nav-link {
			&:not(.disabled) {
				color: $body_links_color;

				&:hover {
					color: $body_links_hover_color;
				}

				&.active {
					font-family: $font_primary_bold;
					font-weight: 700;
					color: $body_links_hover_color;
				}
			}
		}
	}

	// Nav pills
	&.nav-pills {
		.nav-link {
			&:not(.disabled):not(.active) {
				color: $body_links_color;

				&:hover {
					color: $body_links_hover_color;
				}
			}

			&.active {
				font-family: $font_primary_bold;
				font-weight: 700;
			}
		}
	}

	// Nav tabs
	&.nav-tabs {
		li {
			&:before, &:after {
				display: none;
			}
		}
	}
}

/*=============================
// Menu primary at header
=============================*/

$header_item_space: 2.632rem;

#header {
	nav {
		&.menu-primary {
			margin-bottom: -$header_item_space / 2;
			margin-right: -$header_item_space / 2;

			ul.nav {
				@extend .justify-content-end;

				li.nav-item {
					padding-bottom: $header_item_space / 2;
					margin-right: $header_item_space / 2;

					a {
						opacity: 1;
					}

					> a {
						position: relative;
						color: $menu_links_color;
						text-transform: uppercase;
					}

					> .dropdown-custom-icon {
						.dropdown-icon {
							color: $menu_links_color;
						}
					}

					&:hover {
						> a {
							color: $menu_links_hover_color;
						}

						> .dropdown-custom-icon {
							.dropdown-icon {
								color: $menu_links_hover_color;
							}
						}
					}

					&.current-nav-item, &.active, &.show {
						> a {
							color: $menu_links_active_color;
						}

						> .dropdown-custom-icon {
							.dropdown-icon {
								color: $menu_links_active_color;
							}
						}
					}

					// Dropdown
					> .dropdown-custom-icon {
						&:hover {
							color: $menu_links_active_color;

							.dropdown-icon {
								color: $menu_links_active_color;
							}
						}

						.dropdown-icon {
							@extend .transition-all;
						}
					}

					.dropdown-custom-icon {
						.dropdown-icon {
							padding-left: $header_item_space / 6;
						}
					}
				}
			}
		}
	}
}

/*=============================
// Menu primary at modal
=============================*/
$menu_primary_header_fz: 1.737rem;
$menu_primary_header_mb: 0.842rem;

#menu-modal {
	nav {
		&.menu-primary {
			ul.nav {
				li.nav-item {
					margin-top: $menu_primary_header_mb / 2;
					margin-bottom: $menu_primary_header_mb / 2;
					line-height: normal;
					text-align: right;

					&:before, &:after {
						display: none;
					}

					a {
						font-family: $font_primary_bold;
						font-weight: 700;
						opacity: 1;
					}

					> a {
						color: $menu_modal_links_color;
						font-size: $menu_primary_header_fz;
						position: relative;
					}

					&:hover {
						> a {
							color: $menu_modal_links_hover_color !important;
						}

						> .dropdown-custom-icon {
							.dropdown-icon {
								color: $menu_modal_links_hover_color;
							}
						}
					}

					&.current-nav-item, &.active, &.show {
						> a {
							color: $menu_modal_links_active_color;
						}

						> .dropdown-custom-icon {
							.dropdown-icon {
								color: $menu_modal_links_active_color;
							}
						}
					}

					// Dropdown
					> .dropdown-custom-icon {
						color: $menu_modal_links_color;
						padding-left: $menu_primary_header_mb / 1.35;
						font-size: $menu_primary_header_fz / 1.5;

						&:hover {
							color: $menu_modal_links_active_color;

							.dropdown-icon {
								color: $menu_modal_links_active_color;
							}
						}

						.dropdown-icon {
							@extend .transition-all;
							padding-left: 0;
						}
					}

					.dropdown-menu {
						position: relative;
						top: 0;
						float: none;
						padding-bottom: 0;
						background-color: transparent;
						margin-right: $menu_primary_header_mb + 0.5rem;

						li {
							width: 100%;
							margin-bottom: $menu_primary_header_mb / 2 !important;

							&:first-child {
								margin-top: $menu_primary_header_mb / 2 !important;
							}

							&:last-child {
								margin-bottom: 0 !important;
							}

							> .dropdown-custom-icon {
								padding-left: $menu_primary_header_mb / 1.75;
								font-size: $menu_primary_header_fz / 1.75;
							}

							.dropdown-icon {
								position: static;
								top: 0;
								@include transform(translateY(0));
								right: 0;
							}

							a {
								font-size: $menu_primary_header_fz / 1.35;
							}
						}
					}
				}
			}
		}
	}
}

/*=============================
// Menu secondary at footer
=============================*/
.site-footer {
	nav {
		&.menu-secondary {
			ul {
				li {
					margin-right: 2.105rem;
					margin-bottom: 0;

					&:last-child {
						margin-right: 0;
					}

					&:before {
						display: none;
					}

					i {
						font-size: 1.053rem;
					}

					> a:not(.disabled):not(.active) {
						color: $footer_color;
					}

					&:hover {
						> a:not(.disabled):not(.active) {
							color: $footer_color;
							opacity: 0.5;
						}
					}
				}
			}
		}
	}
}
