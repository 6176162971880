//=================================================
// Blog
//=================================================

/*=============================
// Card post
=============================*/
.card-post {
	@extend .style-default;
	background-color: $card_background_color;
	border: 0;
	height: 100%;

	.img {
		&.object-fit {
			@media #{$media_md} {
				max-height: 185px;
				overflow: hidden;
			}

			&:before {
				padding-top: 66.77%;
			}
		}
	}

	.card-body {
		padding: $card-spacer-y 0;
		padding-bottom: 0;

		.card-title {
			a {
				display: block;
				color: $body_links_color;

				&:hover {
					color: $body_links_hover_color;
				}
			}
		}

		.card-meta {
			position: relative;
			margin-bottom: 1.21rem;

			p {
				font-size: $f_size_df;
			}
		}
	}

	&.sticky {
		box-shadow: 0 5px 83px 0 rgba($body_main_element_secondary_color, 0.12);

		.card-body {
			padding: $card-spacer-y $card-spacer-x !important;
		}
	}

	&.no-thumbnail {
		.card-body {
			padding-top: 0;
		}
	}
}
