//=================================================
// Team
//=================================================

.team-member {
	@extend .style-default;

	.img {
		&.object-fit {
			@media #{$media_md} {
				max-height: 306px;
				overflow: hidden;
			}

			&:before {
				padding-top: 110%;
			}
		}
	}

	.team-member-top {
		position: relative;

		.team-member-position {
			position: absolute;
			z-index: 2;
			left: 1.316rem;
			bottom: 1.316rem;
			background-color: $body_main_element_primary_color;
			padding: 1.053rem 1.316rem;
			min-width: 9.421rem;
			text-align: center;

			> * {
				color: $body_text_tertiary_color;
				font-family: $font_primary_bold;
				font-weight: 700;
			}
		}
	}

	.team-member-content {
		padding-top: $space_xs;

		.team-member-t-head {
			color: $body_headline_color;
		}
	}

	.team-member-nav-items {
		padding-top: $space_xs + 0.158rem;

		ul {
			li {
				margin-right: 1.579rem;

				&:last-child {
					margin-right: 0;
				}

				a {
					font-size: 1.053rem;
				}
			}
		}
	}
}
