//=================================================
// Modal
//=================================================

.modal {
    background-color: $modal_background_color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    min-width: 320px;

    .modal-dialog {
        .modal-header {
            .close {
                &.btn {
                    padding: 0;
                    margin: 0 0 0 auto;
                    font-size: 1.263rem;
                }
            }
        }

        .modal-content {
            [class*='menu-'] {
                .nav {
                    @extend .flex-column;
                }
            }

            .modal-text {
                color: rgba($body_text_tertiary_color, 0.5);

                a {
                    &:not(.btn) {
                        color: $modal_content_color;
                    }
                }

                p {
                    margin-bottom: $space_pc_primary - $p_font_mb;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            // Color
            color: $modal_content_color;

            h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
            del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
            ol, ul, li, fieldset, legend, label, span, caption, figcaption, details, hgroup, summary, mark, audio, video {
                &:not(.btn) {
                    color: inherit;
                }
            }

            a {
                &:not(.transform-scale-h):not(.btn) {
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            .close {
                span {
                    color: $modal_content_color;
                }
            }
        }

        .modal-footer {
            .modal-text {
                font-size: $f_size_df;

                p {
                    font-size: $f_size_df;
                }
            }
        }
    }
}

.logged-in {
    &.admin-bar {
        .modal {
            padding-top: 32px;

            @media #{"(max-width: 782px)"} {
                padding-top: 46px;
            }
        }

        &.header-fixed {
            .modal {
                @media #{"(max-width: 600px)"} {
                    padding-top: 0;
                }
            }
        }
    }
}

/*=============================
// Modal size
=============================*/
.modal-full {
    max-width: 100%;
    margin: 0;
}

/*=============================
// Modal header
=============================*/
.modal-header-top {
    position: absolute;
    z-index: 99;
    width: 100%;
}

/*=============================
// Modal body
=============================*/
.modal-body-centered {
    display: flex;
    align-items: center;
    min-height: 100%;
    padding: 0;

    &::before {
        content: "";
        display: block;
        height: 100vh;
    }
}

/*=============================
// Modal title
=============================*/
.modal-title {
    margin-bottom: 0 !important;
    @extend .after;
}

/*=============================
// Modal menu
=============================*/
#menu-modal {
    .modal-body {
        > * {
            padding-top: 7.843rem;
            padding-bottom: 5.841rem;
            padding-right: 8.5%;

            @media #{$media_sm} {
                padding-bottom: 0;
            }
        }

        .modal-text {
            padding-top: 1.053rem;
            padding-bottom: 1.053rem;

            @media #{$media_sm} {
                padding-top: 2.105rem;
            }
        }
    }

    .modal-footer {
        position: absolute;
        bottom: $space_lg;
        width: 100%;
        padding-top: 2.105rem;

        @media #{$media_sm} {
            position: relative;
            bottom: 0;
            padding-bottom: $space_lg;
        }

        .modal-text {
            @media #{$media_sm} {
                margin-bottom: $space_xs;
            }
        }
    }
}

/*=============================
// Modal search
=============================*/
#search-modal {
    .modal-body {
        form {
            width: 100%;
            max-width: 1250px;
            padding-left: 5.263rem;
            padding-right: 5.263rem;
            margin: auto;

            @media #{$media_md} {
                padding-left: 2.632rem;
                padding-right: 2.632rem;
            }
        }
    }

    .modal-footer {
        position: absolute;
        bottom: $space_lg;
        width: 100%;

        .modal-text {
            @media #{$media_sm} {
                margin-bottom: $space_xs;
            }
        }
    }
}

/*=============================
// Modal plans
=============================*/
.plans-modal {
    padding-left: 0 !important;
    padding-right: 17px !important;

    .modal-body {
        .plans-modal-content {
            width: 100%;
            max-width: 1250px;
            margin: auto;
            padding-left: 5.263rem;
            padding-right: 5.263rem;
            padding-top: 7.843rem;
            padding-bottom: 5.841rem;

            @media #{$media_md} {
                padding-left: 2.632rem;
                padding-right: 2.632rem;
            }

            .modal-t-head {
                margin-bottom: $space_lg;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
