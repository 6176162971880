//=================================================
// About
//=================================================

.about {
	@extend .style-default;
	position: relative;

	@media #{$media_md} {
		display: flex;
		flex-direction: column;
	}

	.about-content {
		position: absolute;
		top: 0;
		z-index: 2;
		width: 56%;
		max-width: 877px;
		background-color: $body_main_element_primary_color;
		color: $body_text_tertiary_color;
		padding: $space_lg;

		@media #{$media_xxl} {
			padding: $space_md;
		}

		@media #{$media_md} {
			position: static;
			width: 100%;
			order: 2;
		}

		.item-t-head{
			color: rgba($body_text_tertiary_color, 0.7);
		}

		.item-description {
			color: rgba($body_text_tertiary_color, 0.7);
		}
	}

	.about-img {
		position: relative;
		width: 74.1%;
		float: right;

		@media #{$media_md} {
			width: 100%;
			float: none;
			order: 1;
		}

		.img {
			margin-top: 14.5%;
			margin-right: 11.5%;
			z-index: 1;

			@media #{$media_lg} {
				margin-top: 25%;
			}

			@media #{$media_md} {
				margin-top: 0;
				margin-right: 0;
			}

			&.object-fit {
				&:before {
					padding-top: 68%;
				}
			}
		}

		.img-sec {
			position: absolute;
			top: 0;
			right: 0;

			@media #{$media_md} {
				display: none;
			}

			img {
				width: 19.895rem;
				height: 19.895rem;
			}
		}
	}
}
