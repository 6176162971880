//=================================================
// Single
//=================================================

#single {
	.single-content {
		.img {
			margin-bottom: $space_lg;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.meta {
			&:first-child {
				margin-top: 0;
			}

			p {
				font-size: $f_size_df;
			}

			span {
				margin-right: 0.263rem;

				&:last-child {
					margin-right: 0;
				}

				a {
					color: inherit;

					&:hover {
						color: $body_links_hover_color;
					}
				}
			}
		}

		.title {
			padding-top: $space_lg;

			&:first-child {
				padding-top: 0;
			}
		}

		.description {
			margin-top: $space_lg - 0.49499999999999994rem;

			&:first-child {
				margin-top: 0;
			}

			input, textarea, select {
				@extend .form-lg;
			}

			[class*='tag-cloud'] {
				display: table;
				line-height: normal;
				word-break: break-all;
				margin-right: -$space_xs / 2;
				margin-top: -$space_xs / 2;

				.tag-cloud-link {
					@extend .btn;
					@extend .btn-secondary;
					@extend .btn-sm;
					@extend .btn-hover-main-secondary;
					@extend .min-w-auto;
					margin-top: $space_xs / 2;
					margin-right: $space_xs / 2;
					font-size: $f_size_sm !important;
				}
			}
		}

		.tags {
			padding-top: $space_lg;
			padding-bottom: $space_lg;
			border-bottom: 1px solid $body_border_color;

			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}

			p {
				display: table;
				line-height: normal;
				word-break: break-all;
				margin-right: -$space_xs / 2;
				margin-top: -$space_xs / 2;

				&:before, &:after {
					display: none;
				}

				a {
					@extend .btn;
					@extend .btn-secondary;
					@extend .btn-sm;
					@extend .btn-hover-main-secondary;
					@extend .min-w-auto;
					margin-top: $space_xs / 2;
					margin-right: $space_xs / 2;
					font-size: $f_size_sm;
				}
			}
		}
	}
}

/*=============================
// Single post
=============================*/
.single-post, [class*='single-'] {
	#single {
		.single-content {
			.img {
				&.object-fit {
					&:before {
						padding-top: 57.155%;

						@media #{$media_md} {
							padding-top: 66.77%;
						}
					}
				}
			}
		}
	}
}
