//=================================================
// Card
//=================================================

.card {
    .card-header {
        margin-bottom: 0 !important;
        @extend .after;
    }

    .card-body {
        padding: $card_spacer_y $card_spacer_x;
    }

    .card-meta {
        span {
            a {
                color: inherit;

                &:hover {
                    color: $body_links_hover_color;
                }
            }
        }
    }

    ul {
        margin-bottom: 0 !important;
    }
}

h1.card-title {
    @extend .h1;
}

h2.card-title {
    @extend .h2;
}

h3.card-title {
    @extend .h3;
}

h4.card-title {
    @extend .h4;
}

h5.card-title {
    @extend .h5;
}

h6.card-title {
    @extend .h6;
}

/*=============================
// Card Columns
=============================*/
.card-columns {
    &.columns-4 {
        column-count: 4;
    }

    &.column-no-gap {
        column-gap: 0;
    }
}

/*=============================
// Accordion card
=============================*/
.accordion {
    .card {
        .card-header {
            position: relative;
            border: 0;
        }
    }

    .btn-link {
        width: 100%;
        text-align: left;

        [class*='fa-'] {
            position: absolute;
            top: 50%;
            right: $card-spacer-x;
            @include transform(translateY(-50%));
            @extend .text-secondary;
        }

        &.collapsed {
            [class*='fa-'] {
                @include transform(translateY(-50%) rotate(-180deg));
            }
        }
    }
}
